export enum SOCKET_CONST {
  MSG_TO_CLIENT = 'msgToClient',
  MSG_TO_SERVER = 'msgToServer',

  // algorithms
  ELEGAS_SOCKET = 'elegasSocket',
  FIRST_HAR_FREQ = 'firstHarFreq',
  DETECTOR_SOCKET = 'detectorSocket',
  ANALYZER_SOCKET = 'analyzerSocket',
  BREAK_DETECTOR_SOCKET = 'breakDetectorSocket',
  FERRO_RESONANCE_SOCKET = 'ferroResonanceSocket',
  GIMME_CURRENT_STATE_SOCKET = 'gimmeCurrentStateSocket',
  SHORT_CIRCUIT_SOCKET = 'shortCircuitSocket',
  TRANSFORMER_STATE = 'transformer_state',

  SUBSTATION_STATE = 'substation_state',

  LIVE_CHARTING = 'livecharting'
}



