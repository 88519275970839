



























import Vue from 'vue';
import { Component, Ref, Watch } from 'vue-property-decorator';
import { Getter, Mutation } from 'vuex-class';

import TopMenu from '@/components/top-menu/TopMenu.vue';
import EquipTree from '@/components/equipment-tree/EquipTree.vue';
import CustomScrollBar from '@/components/basics/CustomScrollBar.vue';
import Resizable from '@/components/basics/Resizable.vue';
import { LAYOUT } from '@/store/layout/constants';
import { EventAggregator } from '@/events';
import { EVN__MAIN_VIEW_SIZE_CHANGED } from '@/events/constants';

@Component({
  components: {
    'top-menu': TopMenu,
    'tree-view': EquipTree,
    'custom-scrollbar': CustomScrollBar,
    resizable: Resizable,
  },
})
export default class MainView extends Vue {
  @Getter(LAYOUT.GET_DRAWER_WIDTH, { namespace: LAYOUT.LAYOUT }) drawerWidth!: number;
  @Getter(LAYOUT.GET_DRAWER_VISIBLE, { namespace: LAYOUT.LAYOUT }) drawerVisible!: boolean;
  @Mutation(LAYOUT.SET_DRAWER_WIDTH, { namespace: LAYOUT.LAYOUT }) setDrawerWidth!: Function;
  @Ref('right-content-ref') rcDiv: any;

  get rightContentWidth() {
    return this.drawerVisible ? `calc(100vw - ${this.drawerWidth + 51}px)` : '100vw';
  }

  @Watch('rightContentWidth') onRightContentWidth(val: number, old: number) {
    if (val === old) return;
    // event bus bike
    EventAggregator.publish({
      theme: EVN__MAIN_VIEW_SIZE_CHANGED,
    });
  }
}
