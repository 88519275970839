export const EQUIP_TYPE = {
  '22': {
    note: 'Распределительное устройство',
    icon: 'class="las la-images'
  },
  '23': {
    note: 'Ячейка присоединения',
    icon: 'las la-microchip'
  },
  '24': {
    note: 'Секция шин',
    icon: 'las la-stream'
  },
  '25': {
    note: 'Трансформатор тока',
    icon: 'las la-cube'
  },
  '28': {
    note: 'РПН',
    icon: 'las la-cube'
  },
  '32': {
    note: 'Высокочастотный заградитель',
    icon: 'las la-database'
  },
  '34': {
    note: 'Заземляющий нож',
    icon: 'las la-wave-square'
  },
  '39': {
    note: 'Время',
    icon: 'las la-cube'
  },
  '36': {
    note: 'Целое',
    icon: 'las la-cube'
  },
  '40': {
    note: 'Уникальный идентификатор',
    icon: 'las la-poll'
  },
  bay: {
    note: 'Автотрансформатор',
    icon: 'las la-grip-horizontal'
  },
  '186': {
    note: 'Разъединитель',
    icon: 'las la-ticket-alt'
  }
};

export enum GroupType {
  company = 'company',
  substation = 'substation',
  voltageLevel = 'voltageLevel',
  bay = 'bay',
  equipment = 'equipment',
}

export const getIconByType = (type: GroupType) => {
  switch (type){
    case GroupType.company: return 'las la-city';
    case GroupType.substation: return 'las la-industry';
    case GroupType.voltageLevel: return 'las la-bolt';
    case GroupType.bay: return 'las la-sitemap';
    case GroupType.equipment: return 'las la-cube';
    default: return  'las la-wave-square';
  }
}

export const getValueByKey = (object: any, key: string) => {
  return object[key];
};
