import { render, staticRenderFns } from "./MainView.vue?vue&type=template&id=2c32a824&scoped=true&"
import script from "./MainView.vue?vue&type=script&lang=ts&"
export * from "./MainView.vue?vue&type=script&lang=ts&"
import style0 from "./MainView.vue?vue&type=style&index=0&id=2c32a824&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c32a824",
  null
  
)

export default component.exports