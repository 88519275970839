








import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import InlineSvg from 'vue-inline-svg';
import {
  BASIC_BADGE_STYLE,
  QUALITY_FAIL,
  STATE_0,
  STATE_0_60,
  STATE_0_UN,
  STATE_100,
  STATE_100_UN,
  STATE_60_100,
  STATE_60_UN,
} from '@/components/equip-icon/constants';
@Component({
  components: {
    'inline-svg': InlineSvg,
  },
})
export default class EquipIcon extends Vue {
  @Prop() node?: any;

  get getStateStyle() {
    let style = BASIC_BADGE_STYLE;
    const { quality, state } = this.node?.data?.state;
    if (state === null || state === undefined) return null;
    if (Number(state) === 0) {
      style += ` ${STATE_0}`;
    } else if (Number(state) <= 60) {
      style += ` ${STATE_0_60}`;
    } else if (Number(state) < 100) {
      style += ` ${STATE_60_100}`;
    } else if (Number(state) >= 100) {
      style += ` ${STATE_100}`;
    }
    return quality >= 1
      ? `${BASIC_BADGE_STYLE} ${
          state <= 0 ? STATE_0_UN : state >= 100 ? STATE_100_UN : STATE_60_UN
        }`
      : style;
  }

  extractSvg(node: any) {
    try {
      const { type_name } = node.data;
      return require(`../../assets/icons/svg/equip-tree/${type_name.toLowerCase()}.svg`);
    } catch {
      return false;
    }
  }
}
