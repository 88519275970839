
















































import Vue from 'vue';
import { Component, Ref, Watch } from 'vue-property-decorator';
import { Action, Getter, Mutation } from 'vuex-class';
import { EQUIP_STORE_CONST } from '@/store/equipment/constants';
import { IEquip, IUserEquipQuery } from '@/store/equipment/types';
import { getIconByType } from '@/components/equipment-tree/EquipTreeExt';
import { ROUTES } from '@/router/router.constants';
import { DICTIONARIES_CONST } from '@/store/dictionaries/dictionaries.const';
import { BREADCRUMBS } from '@/store/breadcrumbs/constants';
import EquipIcon from '@/components/equip-icon/index.vue';
import { addSubject, removeSubject } from '@/socket';
import { SOCKET_CONST } from '@/socket/socket.constants';
import { SUBSTATION_EQUIPMENT, REPORT_DATA } from '@/store/report-data/constants';
import { IReportQuery } from '@/store/report-data/types';

const { DICTIONARIES, GET_EQUIPMENT_TYPES, GET_TAB_REFS } = DICTIONARIES_CONST;

@Component({
  components: {
    'equip-icon': EquipIcon,
  },
})
export default class EquipTree extends Vue {
  filter = '';
  windowHeight = 0;
  route = `${ROUTES.EQUIPMENT}/`;

  @Ref('scrollBar')
  readonly scrollBar?: any;
  @Ref('stationTree')
  readonly stationTree?: any;

  @Getter(EQUIP_STORE_CONST.TREE, { namespace: EQUIP_STORE_CONST.TREE }) tree?: Node[];
  get treeItems(): Node[] {
    // every tree reload get all substation children
    if (Array.isArray(this.tree) && this.tree.length > 0) {
      this.getSubstationEquipment({ uids: this.tree.map((x: any) => x.uid) });
    }
    return this.tree ?? [];
  }

  @Getter(EQUIP_STORE_CONST.EXPANDED_ITEMS, { namespace: EQUIP_STORE_CONST.TREE })
  expanded!: Set<number>;
  @Getter(EQUIP_STORE_CONST.TREE_LOADING, { namespace: EQUIP_STORE_CONST.TREE })
  isLoading!: boolean;

  @Getter(EQUIP_STORE_CONST.SUBSTATION_CALC_UIDS, { namespace: EQUIP_STORE_CONST.TREE })
  substationCalcUids?: any;

  @Action(EQUIP_STORE_CONST.GET_TREE, { namespace: EQUIP_STORE_CONST.TREE }) getTree!: () => void;

  @Action(GET_EQUIPMENT_TYPES, { namespace: DICTIONARIES })
  getEquipmentTypes!: () => void;

  @Action(GET_TAB_REFS, { namespace: DICTIONARIES })
  getTabRefs!: () => void;

  @Action(SUBSTATION_EQUIPMENT, { namespace: REPORT_DATA })
  getSubstationEquipment!: (payload: any) => void;

  @Action(EQUIP_STORE_CONST.UPDATE_SUBSTATION_STATES, { namespace: EQUIP_STORE_CONST.TREE })
  updateTreeStates!: (data: any) => void;

  @Mutation(BREADCRUMBS.SET_BREADCRUMBS, { namespace: BREADCRUMBS.BREADCRUMBS })
  setBreadcrumbs!: (x: string[]) => void;

  @Mutation(EQUIP_STORE_CONST.TREE_ITEMS_EXPAND, { namespace: EQUIP_STORE_CONST.TREE })
  expandItems!: (keys: Set<number>) => void;
  @Mutation(EQUIP_STORE_CONST.TREE_ITEMS_COLLAPSE, { namespace: EQUIP_STORE_CONST.TREE })
  collapseItems!: (keys: Set<number>) => void;
  @Mutation(EQUIP_STORE_CONST.TREE_ITEMS_COLLAPSE_ALL, { namespace: EQUIP_STORE_CONST.TREE })
  collapseAll!: () => void;

  @Watch('expanded')
  onChangeExpanded(newExpanded: Set<number>, oldExpanded: Set<number>) {
    const nodes = this.stationTree?.store.nodesMap || [];
    const toAdd = [...newExpanded].filter((x) => !oldExpanded.has(x));
    const toDelete = [...oldExpanded].filter((x) => !newExpanded.has(x));

    toDelete.forEach((x) => {
      const node = nodes[x];
      if (node && node.expanded) {
        node.expanded = false;
      }
    });

    toAdd.forEach((x) => {
      const node = nodes[x];
      if (node && !node.expanded) {
        node.expanded = true;
      }
    });
  }

  @Watch('$route.params.id') onChangeId(val?: string, old?: string) {
    if (val && val !== old && this.stationTree) {
      this.stationTree?.setCurrentKey(val);
      setTimeout(() => this.updateBreadcrumbs(), 0);
    }
  }

  @Watch('tree') onChangeTree(val?: any[], old?: any[]) {
    if (val === old || !val) return;

    if (this.$route.params.id === undefined && val[0]) {
      this.$router.push(`${this.route}${val[0].id}`);
      this.setSelectedNode(val[0]);
    }

    setTimeout(() => this.onChangeExpanded(this.expanded, new Set([])), 0);
  }

  @Watch('filter') onFilterChanged(val: string) {
    this.stationTree?.filter(val);
    if (val.length === 0) {
      const nodes = this.stationTree?.store.nodesMap || [];

      Object.values(nodes).forEach((node: any) => {
        if (node.expanded) {
          node.expanded = false;
        }
      });

      this.onChangeExpanded(this.expanded, new Set([]));
    }
  }

  defaultProps = {
    children: 'children',
    label: 'name',
  };

  get hasFilter(): boolean {
    return !this.filter || this.filter.length === 0 || !this.filter.trim();
  }

  get crumbsArray() {
    const id = this.$route.params.id;
    if (this.treeItems && id) {
      return this.getCrumbs(Number(id), this.treeItems) || [];
    } else {
      return [];
    }
  }

  get substationUid(): string | null {
    if (!Array.isArray(this.treeItems) || !this.treeItems?.length) return null;
    return (this.treeItems[0] as any)?.uid ?? null;
  }

  handleFilterReset() {
    this.filter = '';
  }

  getCrumbs(node: number, nodes: any[]): any[] | null {
    const targetNode = nodes.find((x) => x.id === node);
    if (targetNode) {
      return [targetNode];
    } else {
      return nodes
        .map((x) => {
          const crumbs = this.getCrumbs(node, x.children);
          return crumbs ? [x, ...crumbs] : null;
        })
        .filter((x) => !!(x && x.length))[0];
    }
  }

  updateBreadcrumbs() {
    this.setBreadcrumbs(this.crumbsArray.map((x) => x.name));
  }

  getItemIcon(node: any) {
    return 'el-icon-folder';
  }

  getIcon(node: any) {
    const { groupType } = node?.data;
    if (!groupType) {
      return 'las la-question-circle';
    }
    return getIconByType(groupType);
  }

  filterNode(value: any, data: any) {
    if (!value) return true;
    return data?.name?.toLowerCase().indexOf(value.toLowerCase()) > -1;
  }

  onNodeExpand(data: IEquip) {
    this.expandItems(new Set([data.id]));
  }

  onNodeCollapse(data: IEquip) {
    this.collapseItems(new Set([data.id]));
  }

  @Action(EQUIP_STORE_CONST.SELECTED_TREE_NODE, { namespace: EQUIP_STORE_CONST.TREE })
  setSelectedNode!: (data: any) => void;

  onNodeClick(node: any) {
    if (!node) return;
    this.setSelectedNode(node);
    const { id } = node;
    const current = Number(this.$route.params.id);
    if (current !== id) {
      this.$router.push(`${this.route}${id}`);
    }
  }

  subscribeOnStateChange(keys: string[]) {
    addSubject({
      theme: SOCKET_CONST.SUBSTATION_STATE,
      keys,
    });
    this.$socket.client.on(SOCKET_CONST.SUBSTATION_STATE, (data: any) => {
      this.updateTreeStates(data);
    });
  }

  unsubscribeFromStateChange() {
    removeSubject(SOCKET_CONST.SUBSTATION_STATE);
    this.$socket.client.off(SOCKET_CONST.SUBSTATION_STATE);
  }

  async mounted() {
    await Promise.all([this.getTree(), this.getEquipmentTypes(), this.getTabRefs()]);

    const ids: string[] = Object.values(this.substationCalcUids);
    if (ids.length > 0) {
      this.subscribeOnStateChange([...ids]);
    }

    if (this.$route && this.$route.params) {
      const { id } = this.$route.params;
      if (id) {
        this.stationTree?.setCurrentKey(id);
        const node = this.stationTree.getNode(id);
        if (node) {
          this.setSelectedNode(node.data);
        }

        setTimeout(() => this.updateBreadcrumbs(), 0);
      }
    }
  }

  beforeDestroy() {
    this.unsubscribeFromStateChange();
    // this.cron?.removeSchedule();
    this.setBreadcrumbs([]);
  }
}
